import { getExploreName } from '../utils/platform';

export const devConfig = {
  sdkKey: '7_u9KLILQfmalyQvI67ZHw',
  sdkSecret: 'DkbbLti3kvIeCAfSIh10ZCBFibI1ZjXNGLjF',
  webEndpoint: 'zoom.us',
  name: 'Cotter AGRITECH',
  password: '',
  signature: '',
  sessionKey: '',
  userIdentity: '',
  // The user role. 1 to specify host or co-host. 0 to specify participant, Participants can join before the host. The session is started when the first user joins. Be sure to use a number type.
  role: 1
};
